*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
body > .loading-window 
{
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #32323297;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s;
	overflow: hidden;
	z-index: 100;
}
body > .loading-window .box 
{
	width: 10vw;
	height: 10vw;
	background-color: rgb(234, 245, 246);
	border-radius: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
}
body > .loading-window .box img 
{
	width: 70%;
	height: 70%;
}
body.loading .loading-window
{
	opacity: 1;
	visibility: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loginPage 
{
	width: 100%;
	height: 100vh;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(232,237,242);
}
.login 
{
	width: 60%;
	height: 55%;
	min-height: 28vw;
	border-radius: 0.5vw;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.login::after 
{
	content: '';
	display: block;
	width: 70%;
	position: absolute;
	top: 98%;
	left: 15%;
	height: 1.5vw;
	border-radius: 0.5vw;
	filter: blur(3vw);
	background-color: black;
}
.login > div 
{
	width: 50%;
	height: 100%;
	background-color: white;
	z-index: 2;
}

.login > :first-child 
{
	border-top-left-radius: 0.5vw;
	border-bottom-left-radius: 0.5vw;
}
.login > :last-child
{
	border-top-right-radius: 0.5vw;
	border-bottom-right-radius: 0.5vw;
}
.imageContainer 
{
	overflow: hidden;
	position: relative;
	box-shadow: -0.5vw 0 1.5vw rgba(82, 83, 84, 0.526);
}
.loginImage 
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	overflow: hidden;
}
.loginImage img 
{
	height: 100%;
	min-width: 100%;
	position: absolute;
	filter: brightness(0.3) blur(1px);
}
.login-swiper
{
	width: 100%;
	height: 100%;
	padding: 2vw 15%;
	margin: 0;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 5;
	overflow: hidden;
}
.l_role
{
	width: 26%;
	position: absolute;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.l_role.active
{
	left: 37%;
	transition: 0.3s cubic-bezier( 0.49, 0.44, 0.24, 1 );
	transform: scale(1.15); 
}
.l_role.off
{
	left: 110%;
	transition: 0s !important;
	transform: scale(1); 
}
.l_role.prev 
{
	transform: scale(1); 
	left: -50%;
	transition: 0.3s cubic-bezier( 0.24, 0.44, 0.49, 1 ); 
}
.l_image
{
	width: 100%;
}
.l_image img 
{
	width: 100%;
	-webkit-filter: brightness(0.9) drop-shadow(0px 0px 50px #969b9b8a);
    filter:         brightness(0.9) drop-shadow(0px 0px 50px #969b9b8a);
}
.rolename
{
	background: rgb(74,74,74);
	background: linear-gradient(0deg, rgba(74,74,74,1) 0%, rgba(255,255,255,1) 67%);
	background-clip: text;
	color: transparent;
	font-size: 2vw;
	font-weight: 700;
	padding: 0.6vw 0;
}

.formContainer
{
	height: 92% !important;
	padding: 2vh 5vw;
	width: 46% !important;
}
.loginForm 
{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.loginForm .title 
{
	font-size: 2.7vw;
}
.loginForm .subtitle
{
	font-size: 1.4vw;
	color: #3f4344;
	padding: 0.5vw 0;
}

.loginForm .input
{
	width: 100%;
	min-height: 2.6vw;
	border-radius: 0.2vw;
	display: flex;
	overflow: hidden;
	margin: 1vw 0 0.2vw;
	border: 1px solid #cfd6d8;
}
.loginForm .input .sign
{
	width: 2.6vw;
	height: 100%;
	background-color: rgb(240,243,245);
	padding: 0.7vw;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid #cfd6d8;
}
.loginForm .input .sign img 
{
	width: 100%;
	opacity: 0.8;
}
.loginForm .input input 
{
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	padding: 0.5vw 1vw;
	color: #525758;
	font-size: 1.2vw;
}
.loginForm .input.wrong
{
	box-shadow: 0 0 0.5vw rgba(255, 0, 0, 0.302);
}
.loginForm .buttonContainer
{
	width: 100%;
	margin: 2vw 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.loginForm button 
{
	height: 2.7vw;
	background-color: rgb(74,74,74);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 1.1vw;
	outline: none;
	border-radius: 0.2vw;
	padding: 0 3vw;
	border: none;
	cursor: pointer;
}

.nodata
{
	width: 100%;
	display: flex;
	padding: 5vw 0;
	flex-direction: column;
	align-items: center;
}
.errimg
{
	width: 10%;
	margin-bottom: 2vw;
}
.errimg img {
	width: 100%;
}
.errmessage 
{
	color: #7f8d8d;
	font-size: 1.2vw;
}

.admin_back
{
    width: 100%;
    height: 100vh;
	padding-top: 7vh;
	box-sizing: border-box;
	display: flex;
	justify-content: end;
}
.admin_header
{
    width: 82%;
    height: 7vh;
    background-color: rgb(229, 240, 240);
	border-bottom: 1px solid rgb(182, 192, 188);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_header .profile
{
    display: flex;
    justify-content: center;
    align-items: center;
	height: 100%;
	padding-right: 3vw;
}
.profile .avatar 
{
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 50%;
}
.profile .avatar.no_ava
{
	padding: 0.3vw 0 0 0;
}
.profile .avatar img 
{
	width: 100%;
}
.profile .avatar.no_ava img 
{
	filter: opacity(0.3);
}

.profile .ava span 
{
	border: 0.4vw solid transparent; 
	position: absolute;
	top: calc(50% - 0.2vw);
	left: 125%;
	border-top: 0.4vw solid rgb(141, 146, 148);
	cursor: pointer;
}
.profile .ava span.active
{
	transform: rotate(180deg) translate(0, 5px);
}
.admin_header .profile .ava
{
	height: 5vh;
	width: 5vh;
	position: relative;
	border-radius: 50%;
	cursor: pointer;
	margin: 0 1.5vw;
	background: rgb(193, 199, 202);
}
.admin_header .profile .ava .list {
	width: 12vw;
	padding: 0vw 0;
	position: absolute;
	visibility: hidden;
	top: 110%;
	right: calc(-1 * (25% + 0.9vw));
	z-index: 1;
	color: rgb(93, 100, 101);
	background-color: rgb(230, 242, 238);
	border:  0.1vw solid rgba(182, 192, 188, 0.58);
}
.admin_header .profile .ava .list.active {
	visibility: visible;

}
.admin_header .profile .ava .list div, .admin_header .profile .ava .list button{
	padding: 0.8vh 1vw;
	width: 100%;
	display: flex;
	background: transparent;
	outline: none;
	font-size: 1.1vw;
	color: rgb(93, 100, 101);
	border: none;
	align-items: center;
}
.admin_header .profile .ava .list div:hover, .admin_header .profile .ava .list button:hover  {
	background: rgb(207, 217, 214);
}
#logout
{
	margin-top: 0.3vw;
}
.admin_header .profile .name
{
	cursor: pointer;
	color: rgb(102, 115, 114);
}
.admin_header .profile .name:hover
{
	text-decoration: underline;
}

.admin_sidebar 
{
	position: fixed;
	left: 0;
	top: 0;
	width: 18%;
	height: 100%;
	background: rgb(230, 242, 238);
	border-right: 1px solid rgb(182, 192, 188);
}
.wrapper
{
	width: 82%;
	height: 100%;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
}
.wrapper::-webkit-scrollbar
{
	background-color: rgb(226, 238, 240);
	width: 1vw;
}
.wrapper::-webkit-scrollbar-thumb
{
	background-color: #a6d8e5;

}

.admin_main
{
	width: 100%;
	height: 100%;
}

.admin_welcome 
{
	width: 100%;
	height: 100%;
	background-color: rgb(228, 244, 240);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.selectorList
{
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 0 6%;
}

.selector
{
	width: 14%;
	text-decoration: none;
	display: block;
	margin: 0 6%;
	transition: 0.3s;
	transform: translate(0,0);
	opacity: 0.7;
}
.selector_img
{
	width: 100%;
}
.selector_img img
{
	width: 100%;
	filter: brightness(0.82) drop-shadow(0 0 0 rgb(5, 145, 145));
	transition: 0.3s;
}

.selector:hover
{
	transform: translate(0, -1vw);
	cursor: pointer;
	opacity: 1;

}
.selector:hover img
{
	filter: brightness(0.82) drop-shadow(0 0 0.3vw rgba(147, 197, 197, 0.613));
}


.delector_desc
{
	text-align: center;
	margin: 2vh 0;
	color: rgba(93, 100, 101, 0.699);
	font-size: 1.5vw;
}

.admin_title
{
    font-family: "Montserrat";
	text-align: center;
	display: block;
	font-size: 2.3vw;
	text-decoration: solid;
	letter-spacing: 0.1vw;
	color: rgb(0,209,209);
	overflow: hidden;
	margin: 1.5vh 0;
}
.admin_title:hover
{
	text-decoration: underline;
}

.admin_sidebar nav 
{
	width: 100%;
	padding: 4vh 0;
}
.side_button
{
	width: 100%;
	height: 2.5vmax;
	/* background-color: rgb(196, 237, 237); */
	padding: 0 3vh;
	font-size: 1.1vw;
	display: flex;
	align-items: center;
	color: rgb(93, 100, 101);
	cursor: pointer;
	text-decoration: none;
	border-bottom: 0.1vw solid rgba(182, 192, 188, 0.58);
}
.admin_sidebar nav :last-child
{
	border: none;
}
.side_button.openbar 
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.5s;
}
.side_button.openbar.opened
{
	background: rgb(208, 220, 217);
}
.side_button.priority-2
{
	display: none;
	opacity: 0;
	transition: 0.3s;
}
.side_button.priority-2.displayed
{
	display: flex;
	opacity: 1;
}
.side_button.openbar span 
{
	width: 1vw;
	height: 1vw;
	margin-top: 0.5vw;
	position: relative;
}
.side_button.openbar span::before , .side_button.openbar span::after 
{
	content: '';
	display: block;
	width: 60%;
	height: 0.3vh;
	background-color: rgb(152, 165, 167);
	position: absolute;
}
.side_button.openbar span::before
{
	top: calc(50% - 0.15vh);
	left: 0;
	transform: rotate(40deg);
}
.side_button.openbar span::after
{
	top: calc(50% - 0.15vh);
	right: 0;
	transform: rotate(-40deg);
} 

.side_button.openbar.opened span::before 
{
	transform: rotate(-40deg);
}
.side_button.openbar.opened span::after
{
	transform: rotate(40deg);
}
.side_button:hover {
	background: rgb(207, 217, 214);
	transition: 0.2s;
}
.side_button.active
{
	background: rgb(219, 231, 228);
}

.admin_page
{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.contentBack {
	width: 100%;
	min-height: 100%;
	background-color: rgb(228, 244, 240);
	padding: 1vh 3vw;
	pointer-events: fill;
	padding-top: calc(3vw + 6vh);
}
.contentBack .title 
{
	text-align: center;
	font-size: 3vw;
	margin: 3vh 0;
	font-family: "Montserrat";
	color: rgb(0,209,209, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
}
.table
{
	width: 100%;
	border: 0.2vw solid rgb(0,209,209, 0.7);
	margin-top: 6vh;
	font-family: "Montserrat";
}
.table .row
{
	display: flex;
	border-bottom: 0.2vw solid rgb(0,209,209, 0.7);
	height: 2.7vw;
}
.table :last-child
{
	border: none;
}
.table .row .column
{
	border-right: 0.2vw solid rgba(0, 209, 209, 0.7);
	padding: 0.9vh 0.4vw;
	white-space: nowrap;
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.8vw;
}
.table .row .column span
{
	max-width: 95%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}
.table .row .column.id 
{
	overflow: visible;
	text-overflow: clip;
	width: 5%;
}
.row .clone_name
{
	width: 10%;
}
.row .img 
{
	width: 13%;
}
.row .priority
{
	width: 5%;
}
.row .side
{
	width: 5%;
}
.row .name
{
	width: 20%;
}
.row .info{
	width: 52%;
}
.row.bar .info
{
	width: 62%;
}
.table .row .column.info
{
	text-align: left;
}
.table .row.bar .column
{
	font-weight: bold;
	color: #7f8d8d;
	text-align: center !important;
}

.table .row .column.toolbar 
{
	width: 10%;
	display: flex;
	justify-content: space-between;
	padding: 0.2vw 1.3vw;
	align-items: center;
}
.row .toolbar .tool 
{
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.1vw 0.2vw;
	border-radius: 0.2vw;
	cursor: pointer;
	transition: 0.2s;
	transform: scale(1);
	box-shadow: 0 0 0 rgba(184, 214, 208, 0.626);
} 
.row .toolbar .tool img {
	height: 80%;
}
.row .toolbar .tool.edit
{
	background-color: rgb(147, 222, 159);
}
.row .toolbar .tool.view
{
	background-color: rgb(207, 215, 220);
}
.row .toolbar .tool:hover
{
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}
.row .toolbar .tool.view:hover
{
	background-color: rgb(177, 185, 190);
}
.row .toolbar .tool.edit:hover
{
	background-color: rgb(94, 221, 116);
}


.table .row :last-child
{
	border: none;
}

.view_page, .edit_page, .delete_check, .adding_page, .users_page
{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;
}
.edit_content
{
	padding-bottom: 8vh !important;
}
.view_page.actived, .edit_page.actived, .delete_check.actived, .adding_page.actived, .users_page.actived
{
	visibility: visible;
	opacity: 1;
}
.view_page .shadow, .edit_page .shadow, .delete_check .shadow, .adding_page .shadow, .users_page .shadow
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(37, 37, 37, 0.613);
}
.view_content, .edit_content, .delete_content, .adding_content, .users_content
{
	width: 70%;
	height: 80%;
	background-color: rgb(231, 243, 243);
	position: relative;
	z-index: 5;
	border-radius: 1vw;
	padding: 9vh 3vw 4vh;
	font-family: "Montserrat";
}

.delete_content, .edit_content
{
	height: 25%;
	padding: 4vh 5vw 3vw;
	font-family: "Montserrat";
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.edit_page[type=service] .edit_content
{
	height: auto !important;
	min-height: 30%;
	padding: 6vh 10vh;
}
.adding_page[type=user] .adding_content
{
	height: 80%;
}

.adding_page[type=article] .adding_content
{
	height: 65%;
}
.adding_page[type=publication] .adding_content
{
	height: 80%;
}
.adding_page[type=publication] .adding_content .data, .adding_page[type=article] .adding_content .data
{
	height: 90%;
	overflow-y: hidden;
}
.adding_page[type=article] .adding_content .data
{
	overflow-y: auto;
}
.adding_page[type=publication] .adding_content .inputes
{
	display: flex;
	flex-direction: column;
	justify-content: start;
	height: 90%;
	overflow-y: auto;
}
.adding_page[type=publication] .adding_content .inputes::-webkit-scrollbar
{
	width: 0.5vw;
	background-color: rgb(213, 224, 222);
}
.adding_page[type=publication] .adding_content .inputes::-webkit-scrollbar-thumb
{
	background-color: rgb(161, 220, 221);
}
.adding_page[type=publication] .adding_content .inputes .part
{
	width: 100%;
	padding: 3vh 2vw;
	justify-content: space-between;
}
.adding_page[type=publication] .adding_content .inputes .part input 
{
	width: 50%;
}
.adding_page .adding_content .inputes select 
{
	width: 50%;
	border: none;
    outline: none;
    background-color: transparent;
    line-height: 1.5em;
  	padding: 0.5em 3.5em 0.7em 1em;
	display: inline-block;
    font-size: 1.1vw;
    margin: 0 1vw;
    color: rgb(48, 48, 48);
    box-shadow: 0 0 0.5vw 0.2vw rgba(157, 169, 171, 0.182);
	border-radius: 1vw;
	background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
.adding_page .adding_content .inputes  select:focus 
{
	background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.adding_page[type=publication] .adding_content .inputes .part#media[type='video'] label.video 
{
	width: 50%;
	height: 4.5vh;
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	margin: 0 1vw;
	border-radius: 1vw;
	background-color: rgb(210, 229, 239);
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
}
.adding_page[type=publication] .adding_content .inputes .part#media[type='video'] label.video .name
{
	width: 100%;
	padding: 0 2vw;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #3b3b3c;
}
.adding_page[type=publication] .adding_content .inputes .part#media label.video.plus::before, .adding_page[type=publication] .adding_content .inputes .part#media label.video.plus::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9d2;
	width: 1.5vmin;
	height: 0.3vmin;
}
.adding_page[type=publication] .adding_content .inputes .part#media label.video.plus::after
{
	transform: rotate(90deg);
}
.adding_page[type=publication] .adding_content .inputes .part#media[type='swiper']
{
	flex-direction: column;
}
.adding_page[type=publication] .adding_content .inputes .part#media > label 
{
	margin: 2vh 0;
}
.adding_page[type=publication] .adding_content .inputes .part#media .images
{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	background-color: #e2ecee;
	padding: 2% 2vw;
	border-radius: 0.5vw;
	box-sizing: border-box;
} 
.adding_page[type=publication] .adding_content .inputes .part#media .images .photo
{
	width: 30%;
	height: 14vh;
	margin: calc(1% + 0.3vw);
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	border-radius: 0.5vw;
	overflow: hidden;
	background-color: rgb(192, 224, 240);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center
}
.adding_page[type=publication] .adding_content .inputes .part#media .images .photo .image
{
	max-width: 100%;
	max-height: 100%;
}
.adding_page[type=publication] .adding_content .inputes .part#media .images .photo .shadow 
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #32323254;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s;
	opacity: 0;
	cursor: pointer;
}
.adding_page[type=publication] .adding_content .inputes .part#media .images .photo .shadow:hover
{
	opacity: 1;
}
.adding_page[type=publication] .adding_content .inputes .part#media .images .photo .shadow img 
{
	width: 15%;
}

.adding_page[type=publication] .adding_content .inputes .part#media input 
{
	opacity: 0;
	width: 0;
	height: 0;
	line-height: 0;
	overflow: hidden;
	visibility: hidden;
	display: none;
}

.adding_page[type=publication] .adding_content .inputes .part#media .images label
{
	width: 30%;
	height: 14vh;
	margin: calc(1% + 0.3vw);
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	border-radius: 0.5vw;
	background-color: rgb(192, 224, 240);
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
}
.adding_page[type=publication] .adding_content .inputes .part#media .images label::before, .adding_page[type=publication] .adding_content .inputes .part#media .images label::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9d2;
	width: 3vmin;
	height: 0.4vmin;
}
.adding_page[type=publication] .adding_content .inputes .part#media .images label::after
{
	transform: rotate(90deg);
}

.fixed_author 
{
	width: 50%;
	padding: 0 0.5vw ;
	height: 4vw;
	align-items: center;
	border-radius: 1vw;
	color: #3b3b3c;
	font-size: 1.2vw;
	margin: 0 1vw;
	display: flex;
}

select::-ms-expand {	
	display: none;
}
.adding_page[type=group] .adding_content
{
	height: auto;
	min-height: 45%;
}
.adding_page[type=group] .adding_content .data
{
	height: auto;
}

.adding_page[type=categoryadd] .adding_content 
{
	height: 70%;
}
.adding_page[type=categoryadd] .adding_content .data 
{
	height: 90%;
	overflow: hidden;
}

.adding_page[type=categoryadd] .adding_content .data .inputes.categoryadd
{
	height: 75%;
	padding: 2vh 0;
	flex-direction: column;
	overflow-y: auto;
}
.adding_page[type=categoryadd] .adding_content .data .inputes.categoryadd .name 
{
	width: 30%;
}
.adding_page[type=categoryadd] .adding_content .data .inputes.categoryadd .part 
{
	width: 100%;
	justify-content: space-between;
	padding: 0 20%;
}
.adding_page[type=categoryadd] .adding_content .data .inputes.categoryadd .part input 
{
	width: 70%;
}

.adding_page .img_file input 
{
	opacity: 0;
	width: 0;
	height: 0;
	line-height: 0;
	overflow: hidden;
	visibility: hidden;
	display: none;
}

.adding_page .img_file
{
	width: 40%;
	height: 6vh;
	margin: 0 15%;
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	border-radius: 0.5vw;
	background-color: rgb(192, 224, 240);
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
}
.adding_page .img_file::before, .adding_page .img_file::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9d2;
	width: 3vmin;
	height: 0.4vmin;
}
.adding_page .img_file::after
{
	transform: rotate(90deg);
}
.adding_page .img_file.selected
{
	height: 14vh;
}
.adding_page .img_file .photo 
{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 2;
}
.adding_page .img_file .photo img 
{
	max-width: 100%;
	max-height: 100%;
}


.adding_content
{
	padding-bottom: 2vh !important;
	height: 50%;
}
.adding_content .data
{
	height: 80%;
	padding: 1vh 0;
}
/* ------------------ USERS PAGE -------------------- */

.users_content .title 
{
	text-align: center;
	font-size: 3vw;
	color: #a1a9aa;
}

.users 
{
	width: 100%;
	max-height: 80%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 3vw;
}

.users .user 
{
	width: 100%;
	height: 6vw;
	display: flex;
	align-items: center;
	margin: 2vw 0;
	padding: 1vw 0;
	position: relative;
	box-shadow: 0 0.2vw 1vw rgba(96, 110, 110, 0.329);
}
.user_ava 
{
	margin: 0 2vw;
	height: 100%;
	width: 4vw;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	background-color: #a2acaf;
}
.users .user .user_ava .add_img
{
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.301);
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 2;
	transition: 0.2s cubic-bezier(0.35, 1.24, 0, 0.96);
	cursor: pointer;
}
.users .user .user_ava .add_img:hover 
{
	opacity: 1;
}

.users .user .user_ava .add_img img
{
	width: 40%;
	filter: brightness(1);
}
.user_ava.no_ava
{
	padding: 0.5vw 0 0 0;
}
.user_ava.no_ava > img
{
	filter:  opacity(0.3);
}
.user_ava > img 
{
	width: 100%;
}
.users .user_name 
{
	width: 34%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #7f8d8d;
	font-size: 1.2vw;
}
.users .user_role
{
	color: #7f8d8d;
	font-size: 1.2vw;
}
.users .toolbar
{
	display: flex;
	margin-left: 1.5vw;
	position: absolute;
	right: 5%;
}
.users .add 
{
	width: 100%;
	height: 3vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(123, 207, 222, 0.2);
	cursor: pointer;
	transition: 0.2s;
	margin: 2vh 0;
	border-radius: 1vw;
	position: relative;
}
.users .add::before, .users .add::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #989d9d;
	width: 2vmin;
	height: 0.3vmin;
}
.users .add::after
{
	transform: rotate(90deg);
}
.users .add:hover
{
	background-color: rgba(123, 207, 222, 0.5);
}
.users .tool
{
	height: 1.8vw;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: center;
	padding: 0.1vw 0.3vw;
	border-radius: 0.2vw;
	cursor: pointer;
	transition: 0.1s;
	transform: scale(1);
	box-shadow: 0 0 0 rgba(184, 214, 208, 0.626);
	margin: 0 0.5vw;
}
.users .tool img
{
	height: 80%;
}
.users .tool.edit
{
	background-color: rgb(147, 222, 159);
}
.users .tool.delete
{
	background-color: rgb(218, 126, 108);
}

.users .tool.edit:hover
{
	background-color: rgb(94, 221, 116);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}

.users .tool.delete:hover
{
	background-color: rgb(218, 99, 76);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}

/* -------------------------------------------------- */

.inputes
{
	width: 100%;
	padding: 2vw 3vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2vw;
}
.inputes .section 
{
	width: 100%;
	display: flex;
	padding: 2vmax 0
	;
	justify-content: space-between;
}
.inputes textarea 
{
	width: 70%;
	margin: 0 1vw;
	padding: 2vh 2vw;
	border-radius: 1.5vw;
}
.inputes .part
{
	width: 40%;
	padding: 2vmax 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.inputes label 
{
	color: #323232;
	letter-spacing: 0.1vh;
	margin-right: 2vw;
	font-size: 1.1vw;
}
.inputes input, .data input
{
	border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 1vw 1.2vw;
    font-size: 1.1vw;
    margin: 0 1vw;
    color: rgb(48, 48, 48);
    box-shadow: 0 0 0.5vw 0.2vw rgba(157, 169, 171, 0.182);
	border-radius: 1vw;
}
.inputes input:focus 
{
	box-shadow: 0 0 0.5vw 0.2vw rgba(45, 161, 184, 0.248);
}

.inputes.group
{
	width: 100%;
	padding-bottom: 3vh;
	justify-content: center;
}

.inputes.group input
{
	width: 80%;
}
.inputes.article 
{
	flex-direction: column;
	align-items: flex-start;
}
.inputes.article .part 
{
	width: 100%;
	display: flex;
	margin: 1vw 0;
	justify-content: space-between;
}
.inputes.article .part input
{
	width: 70%;
}
.section
{
	display: flex;
	position: relative;
	align-items: center;
}

.key
{
	display: flex;
	width: 10%;
	/* border-right: 0.2vw solid rgb(0,209,209);
	border-left: 0.2vw solid rgb(0,209,209); */
	justify-content: center;
	align-items: center;
	padding: 1vw 2vw;
	/* border-bottom: 0.2vw solid rgba(182, 192, 188, 0.751); */
	margin: 1vh;
	font-size: 1.1vw;
	box-sizing: content-box;
}
.line
{
	width: 0.2vw;
	background-color: rgb(0,209,209);
	position: absolute;
	height: 110%;
	left: 18%;
	display: none;
}
.value
{
	padding: 1vw 2vw;
	background-color: rgba(218, 227, 228, 0.815);
	border-radius: 1.5vw;
	margin: 1vh 2vw;
	font-size: 1.2vw;
	width: 80%;
}

.buttons
{
	width: 100%;
	position: absolute;
	height: 4vh;
	bottom: 1.5vh;
	display: flex;
	justify-content: end;
	box-sizing: border-box;
	left: 0;
	padding: 0 3vw;
}

.cancel
{
	width: 12%;
	height: 100%;
	border-radius: 0.5vw;
	cursor: pointer;
	margin: 0 2vw;
	color: rgb(231, 243, 243);
	display: flex;
	font-size: 1.2vw;
	background-color: #a8baba;
	justify-content: center;
	align-items: center;
	font-weight: 700;
}

.send 
{
	width: 12%;
	height: 100%;
	background-color: #83cde1;
	border-radius: 0.5vw;
	color: rgb(231, 243, 243);
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 1.2vw;
	cursor: pointer;
}

.close
{
	position: absolute;
	cursor: pointer;
	right: 1.5vw;
	top: 2.5vh;
	width: 2.5vw;
	height: 2.5vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
.close::before , .close::after 
{
	content: '';
	display: block;
	width: 100%;
	height: 0.3vh;
	background-color: rgb(173, 195, 186);
	position: absolute;
}
.close::before
{
	transform: rotate(45deg);
}

.close::after
{
	transform: rotate(-45deg);
}
.data
{
	max-height: 100%;
	overflow-y: auto;
}
.data::-webkit-scrollbar , .users::-webkit-scrollbar
{
	width: 0.5vw;
	background-color: rgb(213, 224, 222);
}
.data::-webkit-scrollbar-thumb , .users::-webkit-scrollbar-thumb
{
	background-color: rgb(161, 220, 221);
}
.data .section textarea
{
	height: 100%;
	resize: none;
	overflow-y: hidden;
	overflow-x: auto;
	background-color: transparent;
	outline: none;
	border: 0;
	display: flex;
	font-size: 1.15vw;
	align-items: center;
    box-shadow: 0 0 0.5vw 0.2vw rgba(157, 169, 171, 0.182);
}
.data .section textarea:focus
{
	box-shadow: 0 0 0.5vw rgb(161, 204, 221);
	
}
.data .section textarea.long
{
	overflow-y: scroll;
	resize: vertical;
	min-height: 10vmax;
}

.data .section textarea.long::-webkit-scrollbar
{
	background-color: transparent;
}
.data .section textarea::-webkit-scrollbar-corner
{
	background-color: rgb(171, 231, 240);
	display: block;
	border-radius: 0.5vw;
	position: relative;
	border: none;
}

.mark
{
	width: 2vw;
	height: 2vw;
	position: absolute;
	left: 1.2vw;
	top: 1.2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5vw 0.5vw;
	border-radius: 50%;
}
.mark img 
{
	width: 100%;
	opacity: 0.8;
}
.view_page .mark
{
	background-color: rgb(207, 215, 220);
}
.edit_page .mark
{
	background-color: rgb(147, 222, 159);
}
.adding_page .mark
{
	background-color: rgb(138, 207, 219);
}
.adding_page .mark::after, .adding_page .mark::before
{
	content: '';
	display: block;
	position: absolute;
	width: 50%;
	height: 0.3vh;
	background-color: rgb(228, 244, 240);
}
.adding_page .mark::before
{
	transform: rotate(90deg);
}
.delete_check .mark
{
	background-color: rgb(232, 138, 126);
}

.warn
{
	width: 100%;
	height: 5vh;
	text-align: center;
	color: rgb(93, 100, 101);
	margin: 1vh 0 2vh;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 1.5vw;
}
.variants 
{
	width: 100%;
	padding: 0 10%;
	display: flex;
	justify-content: space-between;
}
.warn span 
{
	color: rgb(228, 105, 99);
}
.warn span .type 
{
	color: rgb(127, 179, 209);
}

.variants .no 
{
	width: 30%;
	height: 5vh;
	background-color: #c5d2d2;
	border-radius: 1.1vw;
	color: rgb(122, 131, 132);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 1.3vw;
	transition: 0.15s;
	transform: scale(1);
}
.variants .yes
{
	width: 30%;
	height: 5vh;
	background-color: #f1968a;
	border-radius: 1.1vw;
	color: rgb(220, 238, 240);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 1.3vw;
	transition: 0.15s;
	transform: scale(1);
}
.variants .yes:hover , .variants .no:hover
{
	transform: scale(1.02);
}


.pricelist 
{
	width: 100%;
	min-height: 100%;
	padding: 7vh 5%;
	background-color: rgb(221, 237, 232);
	font-family: "Montserrat";
	margin: 1vh 0 5vh;
	border-radius: 2vw;
}

.pricelist .group
{
	width: 100%;
}
.pricelist .group_title
{
	width: 100%;
	border-bottom: 0.2vw solid rgb(0,209,209, 0.5);
	padding: 1vw;
	font-size: 1.6vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgb(93, 100, 101);
}
.pricelist .services 
{
	width: 100%;
	padding: 2vw 4vw;
}
.pricelist .services .service
{
	width: 100%;
	padding: 1vw 2vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.2vw solid rgb(0,209,209, 0.5);
	font-size: 1.02vw;
} 
.service .price 
{
	padding: 0 1vw;
	white-space: nowrap;
	display: flex;
	align-items: center;
}
.service .service_name
{
	padding: 0 1vw;
}
.pricelist .services :last-child
{
	border: none;
}
.service .part
{
	display: flex;
}
.pricelist .toolbar
{
	display: flex;
	margin-left: 1.5vw;
}
.pricelist .tool
{
	height: 1.8vw;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: center;
	padding: 0.1vw 0.3vw;
	border-radius: 0.2vw;
	cursor: pointer;
	transition: 0.1s;
	transform: scale(1);
	box-shadow: 0 0 0 rgba(184, 214, 208, 0.626);
	margin: 0 0.5vw;
}
.pricelist .tool img
{
	height: 80%;
}
.pricelist .tool.edit
{
	background-color: rgb(147, 222, 159);
}
.pricelist .tool.delete
{
	background-color: rgb(218, 126, 108);
}

.pricelist .tool.edit:hover
{
	background-color: rgb(94, 221, 116);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}

.pricelist .tool.delete:hover
{
	background-color: rgb(218, 99, 76);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}


.pricelist .add 
{
	width: 100%;
	height: 4vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(123, 207, 222, 0.2);
	cursor: pointer;
	transition: 0.2s;
	margin: 2vh 0;
	border-radius: 1vw;
}
.pricelist .add::before, .pricelist .add::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #989d9d;
	width: 2vmin;
	height: 0.3vmin;
}
.pricelist .add::after
{
	transform: rotate(90deg);
}
.pricelist .add:hover
{
	background-color: rgba(123, 207, 222, 0.5);
}
.add[type='group']
{
	height: 6vh;
	border-radius: 2vw;
}
.edit_page[type=group] .edit_content , .edit_page[type=dentist] .edit_content
{
	height: 70%;
	overflow: hidden;
}
.edit_page[type=group] .data 
{
	width: 100%;
	height: 100%;
	padding: 0 1vw;
	background-color: rgb(231, 243, 243);
	border-radius: 1vw;
	overflow-y: hidden;
} 
.edit_page[type=group] .data .title
{
	width: 100%;
    border-bottom: 0.2vw solid rgb(0,209,209, 0.5);
    padding: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(93, 100, 101);
}
.edit_page[type=group] .edit_list
{
	padding: 1vw 10% 2vw;
	height: 82%;
	margin-top: 1vw;
	overflow-y: auto;
}
.edit_page[type=group] .edit_list :last-child
{
	border: 0 !important;
}
.edit_page[type=article] .edit_content
{
	height: 65%;
}

.edit_page[type=publication] .edit_content
{
	height: 80%;
}
.edit_page[type=publication] .edit_content .data
{
	display: flex;
	flex-direction: column;
	justify-content: start;
	height: 90%;
	overflow-y: auto;
}
.edit_page[type=publication] .edit_content .section
{
	width: 100%;
	padding: 3vh 2vw;
	justify-content: space-between;
}
.edit_page[type=publication] .edit_content .section input 
{
	width: 50%;
}

.edit_page[type=publication] .edit_content .section#media[type='video'] label.video 
{
	width: 50%;
	height: 4.5vh;
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	margin: 0 1vw;
	border-radius: 1vw;
	background-color: rgb(210, 229, 239);
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
}
.edit_page[type=publication] .edit_content .section#media[type='video'] label.video .name
{
	width: 100%;
	padding: 0 2vw;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #3b3b3c;
}
.edit_page[type=publication] .edit_content .section#media label.video.plus::before, .edit_page[type=publication] .edit_content .inputes .part#media label.video.plus::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9d2;
	width: 1.5vmin;
	height: 0.3vmin;
}
.edit_page[type=publication] .edit_content .section#media label.video.plus::after
{
	transform: rotate(90deg);
}
.edit_page[type=publication] .edit_content .section#media[type='swiper']
{
	flex-direction: column;
}
.edit_page[type=publication] .edit_content .section#media > label 
{
	margin: 2vh 0;
}
.edit_page[type=publication] .edit_content .section#media .images
{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	background-color: #e2ecee;
	padding: 2% 2vw;
	border-radius: 0.5vw;
	box-sizing: border-box;
} 
.edit_page[type=publication] .edit_content .section#media .images .photo
{
	width: 30%;
	height: 14vh;
	margin: calc(1% + 0.3vw);
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	border-radius: 0.5vw;
	overflow: hidden;
	background-color: rgb(192, 224, 240);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center
}
.edit_page[type=publication] .edit_content .section#media .images .photo .image
{
	max-width: 100%;
	max-height: 100%;
}
.edit_page[type=publication] .edit_content .section#media .images .photo .shadow 
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #32323254;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s;
	opacity: 0;
	cursor: pointer;
}
.edit_page[type=publication] .edit_content .section#media .images .photo .shadow:hover
{
	opacity: 1;
}
.edit_page[type=publication] .edit_content .section#media .images .photo .shadow img 
{
	width: 15%;
}

.edit_page[type=publication] .edit_content .section#media input 
{
	opacity: 0;
	width: 0;
	height: 0;
	line-height: 0;
	overflow: hidden;
	visibility: hidden;
	display: none;
}

.edit_page[type=publication] .edit_content .section#media .images label
{
	width: 30%;
	height: 14vh;
	margin: calc(1% + 0.3vw);
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	border-radius: 0.5vw;
	background-color: rgb(192, 224, 240);
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
}
.edit_page[type=publication] .edit_content .section#media .images label::before, .edit_page[type=publication] .edit_content .section#media .images label::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9d2;
	width: 3vmin;
	height: 0.4vmin;
}
.edit_page[type=publication] .edit_content .section#media .images label::after
{
	transform: rotate(90deg);
}

.edit_page[type=category] .edit_content 
{
	height: 70%;
}

.edit_page[type=category] .edit_content .data
{
	height: 90%;
	padding: 2vh 0;
	flex-direction: column;
	overflow-y: auto;
}
.edit_page[type=category] .edit_content .data .name 
{
	width: 30%;
}
.edit_page[type=category] .edit_content .data .section
{
	width: 100%;
	justify-content: space-between;
	padding: 2% 5%;
}
.edit_page[type=category] .edit_content .data .section input  , .edit_page[type=article] .edit_content .data .section input
{
	width: 70%;
}

.edit_page[type=article] .edit_content .data .section
{
	padding: 2vmax;
	width: 100%;
	justify-content: space-between;
}

.edit_page .img_file input 
{
	opacity: 0;
	width: 0;
	height: 0;
	line-height: 0;
	overflow: hidden;
	visibility: hidden;
	display: none;
}

.edit_page .img_file
{
	width: 40%;
	height: 20vh;
	margin: 0 15%;
	box-shadow:  0 0 1vw rgba(116, 120, 121, 0.178);
	border-radius: 0.5vw;
	background-color: rgb(192, 224, 240);
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	transition: 0.5s;
}
.edit_page .img_file.empty
{
	height: 7vh;
}
.edit_page .img_file .shadow 
{
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #32323257;
	opacity: 0;
	transition: 0.2s;
	z-index: 10;
	cursor: pointer;
}
.edit_page .img_file .shadow:hover 
{
	opacity: 1;
}
.edit_page .img_file.empty::before, .edit_page .img_file.empty::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9d2;
	width: 3vmin;
	height: 0.4vmin;
}
.edit_page .img_file.empty::after
{
	transform: rotate(90deg);
}
.edit_page .img_file .shadow img 
{
	width: 20%;
}
.edit_page .img_file .photo 
{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 2;
}
.edit_page .img_file .photo img 
{
	max-width: 100%;
	max-height: 100%;
}
.edit_page select 
{
	width: 40%;
    border: none;
    outline: none;
	margin: 0 15%;
    background-color: transparent;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.7em 1em;
    display: inline-block;
    font-size: 1.1vw;
    color: rgb(48, 48, 48);
    box-shadow: 0 0 0.5vw 0.2vw rgba(157, 169, 171, 0.182);
    border-radius: 1vw;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}

.edit_list::-webkit-scrollbar
{
	width: 0.5vw;
	background-color: rgb(213, 224, 222);
}
.edit_list::-webkit-scrollbar-thumb
{
	background-color: rgb(161, 220, 221);
}
.edit_page[type=group] .data input
{
	border: none;
	outline: none;
	background-color: transparent;
	margin: 0;
	padding: 1vw 2vw;
	font-size: 1.1vw;
	margin: 0 1vw;
	color: rgb(48, 48, 48);
	box-shadow: 0 0 0.5vw 0.2vw rgba(157, 169, 171, 0.182);
}
.edit_page[type=group] .data input:focus 
{
	box-shadow: 0 0 0.5vw 0.2vw rgba(45, 161, 184, 0.248);
}

.edit_page[type=group] .data .title input
{
	font-size: 1.9vw;
    color: rgb(93, 100, 101);
	text-align: center;
	border-radius: 2vw;
	width: 100%;
}
.edit_page[type=group] .data .edit_service
{
	width: 100%;
	padding: 1.5vw 1vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.2vw solid rgb(0,209,209, 0.5);
	font-size: 1.02vw;
}
.edit_page[type=group] .data .edit_service .price 
{
	width: 30%;
	text-align: right;
}
.edit_page[type=chapter] .edit_content
{
	height: 30%;	
}

[status="loading"]
{
	background: transparent !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	border: none !important;
}
[status="loading"] img 
{
	width: 10%;
}

.information 
{
	margin: 3vw 0;
	background-color: rgb(228, 244, 247);
	width: 100%;
	border-radius: 2vw;
	padding: 2vw 3%;
	display: flex;
	flex-direction: column;
}

.information .chapter
{
	width: 100%;
	background-color: rgb(221, 239, 244);
	border-radius: 0.8vw;
	padding: 1vw 3%;
	margin: 2vw 0;
	position: relative;
}
.information .chapter_title
{
	width: 100%;
	margin: 2vw 0;
	text-align: center;
	font-size: 3vw;
	color: #9eb7b7;
}
.inforamtion .articles
{
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 2vw;
}
.information .article
{
	width: 100%;
	position: relative;
	border-radius: 0.6vw;
	padding: 4vw 3% 4vw;
	margin: 2vw 0;
	background-color: rgb(204, 228, 235);
	display: flex;
	align-items: center;
	color: #525758;
}
.information .article .articleText 
{
	width: 65%;
	height: 100%;
	font-size: 1.25vw;
	overflow-wrap: break-word;
	padding: 1vw 2vw;
}
.information .article .text #text
{
	white-space: normal;
}
.information .article .articleText.full
{
	width: 100%;
}
.information .article .img 
{
	width: 30%;
	padding: 1vw; 
	background-color: #ced5d6;
	border-radius: 0.5vw;
}
.information .article .img img {
	width: 100%;
}
.information .tool 
{
	position: absolute;
	height: 1.8vw;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: center;
	padding: 0.1vw 0.3vw;
	border-radius: 0.3vw;
	cursor: pointer;
	transition: 0.1s;
	transform: scale(1);
	box-shadow: 0 0 0 rgba(184, 214, 208, 0.626);
	margin: 0 0.5vw;
}
.information .tool img
{
	height: 80%;
}
.information .tool.edit
{
	background-color: rgb(147, 222, 159);
	top: 1vw;
	right: 3vw;
}
.information .tool.delete
{
	background-color: rgb(218, 126, 108);
	top: 1vw;
	right: 0.5vw;
}

.information .tool.edit:hover
{
	background-color: rgb(94, 221, 116);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}

.information .tool.delete:hover
{
	background-color: rgb(218, 99, 76);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}
.information .add 
{
	width: 100%;
	height: 4vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(204, 228, 235, 0.609);
	cursor: pointer;
	transition: 0.2s;
	margin: 2vh 0;
	border-radius: 1vw;
}
.information .add::before, .information .add::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #989d9d;
	width: 2vmin;
	height: 0.3vmin;
}
.information .add::after
{
	transform: rotate(90deg);
}
.information .add:hover
{
	background-color: rgb(204, 228, 235);
}
.information .add[type='chapter']
{
	height: 5vw;
	background-color: rgb(221, 239, 244);
}
.information .add[type='chapter']:hover
{
	background-color: rgb(206, 232, 238);
}

/* ------------------------ Publications ---------------------------- */

.selectbar 
{
	width: calc(100% - 1.5vw);
	margin-left: 1.5vw;
	left: 0;
	display: flex;
	height: 8vh;
	top: calc(3vw + 10vh);
	align-items: center;
	position: absolute;
	z-index: 2;
}
.selectbar::before 
{
	content: '';
	width: calc(100% + 1.5vw);
	height: 2vh;
	background-color: #deedf0;
	position: absolute;
	top: 100%;
	left: -1.5vw;
	z-index: 200;
}


.category 
{
	width: 20%;
	height: 100%;
	background-color: rgb(178, 196, 206);
	border-top-right-radius: 2vh;
	border-top-left-radius: 2vh;
	text-decoration: none;
	color: #2c3334bd;
	position: absolute;
	font-size: 1.3vw;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2vh 3vw;
	transition: 0.5s;
	box-shadow: 0.2vw 0 0.2vw rgba(125, 130, 134, 0.356);
}
.category .name
{
	z-index: 2;
}

.category .back 
{
	position: absolute;
	width: 90%;
	height: 60%;
	top: 0;
	margin: 1.75vh 0;
	display: flex;
	border-top-right-radius: 1vh;
	border-top-left-radius: 1vh;
	opacity: 0.5;
	align-items: center;
	overflow: hidden;
}

.category .back img 
{
	width: 100%;
}
.adding_page[type=article] select 
{
	width: 50%;
    border: none;
    outline: none;
    background-color: transparent;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.7em 1em;
    display: inline-block;
    font-size: 1.1vw;
    margin: 0 1vw;
    color: rgb(48, 48, 48);
    box-shadow: 0 0 0.5vw 0.2vw rgba(157, 169, 171, 0.182);
    border-radius: 1vw;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}
.add[type='category']
{
	width: 20%;
	height: 100%;
	position: absolute;
	background-color: rgb(192, 224, 240);
	cursor: pointer;
	border-top-right-radius: 2vh;
	border-top-left-radius: 2vh;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
	box-shadow: 0.2vw 0 0.2vw rgba(125, 130, 134, 0.356);
}
.add[type='category']::before, .add[type='category']::after
{
	content: '';
	display: block;
	position: absolute;
	background-color: #a2a9a9;
	width: 3vmin;
	height: 0.4vmin;
}
.add[type='category']::after
{
	transform: rotate(90deg);
}

.selectbar .toolbar 
{
	width: 1.5vw;
	height: 100%;
	position: absolute;
	right: 100%;
	box-shadow: 0.1vw 0 0.08vw rgba(128, 128, 128, 0.371);
	top: 0;
	background-color: #deedf0;
	z-index: 150;
	
}
.selectbar .toolbar .dots 
{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 1.7vh 0;
	cursor: pointer;
}
/* cubic-bezier(0.48, 1.36, 0, 0.9) */
.selectbar .toolbar .dots span
{
	width: 0.35vw;
	height: 0.35vw;
	background-color: #7f8d8d;
	border-radius: 50%;
}
.selectbar .bar 
{
	position: absolute;
	top: 110%;
	left: -1%;
	width: 15vw;
	background-color: rgb(192, 224, 240);
	display: flex;
	border-radius: 0.6vw;
	flex-direction: column;
	visibility: hidden;
	opacity: 0;
	box-shadow:  0 0 2vw rgba(107, 112, 113, 0.204);
	transition: 0.3s;
	z-index: 500;
}
.selectbar .bar.active 
{
	visibility: visible;
	opacity: 1;
}
.selectbar .tool
{
	width: 100%;
	padding: 1vw 2vw;
	cursor: pointer;
	display: flex;
	justify-content: space-between;

}
.selectbar .tool:hover 
{
	background-color: #4b5b5f11;
}

.selectbar .tool .icon 
{
	width: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2vw 0.2vw;
	border-radius: 0.2vw;
	cursor: pointer;
	transition: 0.2s;
	transform: scale(1);
	box-shadow: 0 0 0 rgba(184, 214, 208, 0.626);
}

.selectbar .tool .desc 
{
	width: 85%;
	text-align: center;
	color: #7a8283bf;
	font-size: 1.2vw;
}

.selectbar .tool .icon img {
	width: 80%;
}
.selectbar .tool.edit .icon
{
	background-color: rgb(147, 222, 159);
}
.selectbar .tool.view .icon
{
	background-color: rgb(207, 215, 220);
}
.selectbar .tool.delete .icon
{
	background-color: rgb(218, 126, 108);
}
.selectbar .tool:hover .icon
{
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}
.selectbar .tool.view:hover .icon
{
	background-color: rgb(177, 185, 190);
}
.selectbar .tool.edit:hover .icon
{
	background-color: rgb(94, 221, 116);
}
.selectbar .tool.delete:hover .icon
{
	background-color: rgb(218, 126, 108);
}


.pub .tool
{
	height: 1.8vw;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: center;
	padding: 0.1vw 0.3vw;
	border-radius: 0.2vw;
	position: absolute;
	top: 1.5vw;
	cursor: pointer;
	transition: 0.1s;
	transform: scale(1);
	box-shadow: 0 0 0 rgba(184, 214, 208, 0.626);
	margin: 0 0.5vw;
}
.pub .tool img
{
	height: 80%;
}
.pub .tool.edit
{
	background-color: rgb(147, 222, 159);
	right: 5vw;
}
.pub .tool.delete
{
	right: 2vw;
	background-color: rgb(218, 126, 108);
}

.pub .tool.edit:hover
{
	background-color: rgb(94, 221, 116);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}

.pub .tool.delete:hover
{
	background-color: rgb(218, 99, 76);
	transform: scale(1.05);
	box-shadow: 0 0 0.5vw rgba(184, 214, 208, 0.626);
}

.avatar 
{
    position: relative;
    width: 3vw;
    height: 3vw;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin: 0vw 1vw 0.5vw 0vw;
}
.avatar.no_ava
{
    padding-top: 0.5vw;
}
.avatar.no_ava img 
{
    filter: opacity(0.5);
}


.avatar img 
{
    width: 100%;
}

.author_name 
{
    color: rgb(218, 226, 226);
    text-transform: capitalize;
    padding: 1vw 0;
    margin-bottom: 1vw;
}

.category[data-count="0"] 
{
	border-top-left-radius: 0;
}
.category.active 
{
	background-color: #deedf0;
	box-shadow: 0 0 0;
}

.publications .add 
{
	width: 10%;
	height: 3vw; 
	right: 2vw;
	top: 2vmax;
	position: absolute;
	background-color: rgb(192, 224, 240);
	border-radius: 1vw;
	box-shadow: 0 0 0.5vw rgba(64, 65, 66, 0.181);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #9aaaaa;
	font-size: 1.3vw;
	font-weight: 700;
}

.publications
{
	width: 100%;
	height: calc(100% - (3vw + 18vh));
	left: 0;
	top: calc(3vw + 18vh);
	box-shadow: 0 -0.5vw 1vw #6166671d;
	padding: 2vw 20%;
	position: absolute;
	background-color: #deedf0;
	overflow-y: auto;
	overflow-x: hidden;
}
.publications::-webkit-scrollbar
{
	width: 0.7vw;
	background-color: #aec0c0;
}
.publications::-webkit-scrollbar-thumb
{
	background-color: #83cde1;
}
.pub
{
    max-width: 100%;
    background-color: rgb(166, 194, 208);
    border-radius: 2vmax;
    color: rgba(237, 243, 245, 0.769);
    box-shadow: 0 0vw 0.7vw rgba(63, 63, 63, 0.328);
    padding: 3.5vmin 2.5vw 3.5vmin;
    margin: 13vmin 0;
	position: relative;
    display: flex;
    flex-direction: column;
}
 .pub_title
{
    font-size: 2.2vmax;
    margin: 1vh 0;
}
.pub_photos[type='video']
{
	width: 100%;
	height: 40vh;
	display: flex;
	background-color: rgb(149, 166, 175);
	overflow: hidden;
    margin: 4vmin 0;
    border-radius: 1.3vmax;
	align-items: center;
	justify-content: center;
}
.pub_photos[type='video'] video 
{
	max-width: 100%;
	max-height: 100%;
}


 .pub_photos[type='swiper']
{
    width: 100%;
    background-color: rgb(149, 166, 175);
    border-radius: 1.3vmax;
    margin: 4vmin 0;
	overflow: hidden;
    padding: 3% 0;
    position: relative;
}
 .prev_slide {
    width: 4vw;
    position: absolute;
    top: calc(50% - 2vw);
    left: 0;
    z-index: 5;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);
    opacity: 0.6;
}
 .prev_slide img 
{
    width: 100%;
    transform: rotate(180deg);
}
 .next_slide {
    width: 4vw;
    position: absolute;
    top: calc(50% - 2vw);
    right: 0;
    z-index: 5;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);
    opacity: 0.6;
}
 .next_slide:hover ,  .prev_slide:hover {
    transform: scale(1.05);
    opacity: 0.85;
}
 .next_slide:active ,  .prev_slide:active {
    transform: scale(0.95   );
    opacity: 0.9;
}
 .next_slide img 
{
    width: 100%;
}
 .swiper-wrapper {
    box-sizing: border-box;
    transition: 0.3s !important;
	display: flex;
	align-items: center;
}
 .swiper-wrapper.active {
    opacity: 1;
}
 .swiper-wrapper .pub_photo {
	width: 100% !important;
}
 .slide {
    border-radius: 0.7vw;
    cursor: pointer;
	overflow: hidden;
	margin-right: 0 !important;
	margin:  0 50px !important;
}

.swiper-wrapper .slide {
    transition: 0.5s;
}
.slide img
{
	filter: brightness(0.6);
    transform: scale(0.95);
}
 .slide.active_slide img
{
    transform: scale(1.05);
    filter: brightness(1.2);
}

 .pub_text 
{
    font-size: 1vmax;

}




/* 

.admin_welcome > span 
{
	width: 50%;
	height: 0.8vh;
	border-radius: 0.5vh;
	background-color: rgb(121, 205, 193);
	display: block;
} */

